import React from "react";
import Header from "../components/header/header.js";
import { useForm, ValidationError } from "@formspree/react";
import "../assets/styles/pages/_optout.scss";

const OptOut = () => {
  const [state, handleSubmit] = useForm(process.env.GATSBY_FORM_SPREE);

  return (
    <>
      <Header title="OptOut" />
      <div className="container optout">
        <div className="columns">
          <div className="column is-half is-offset-one-quarter">
            {state.succeeded ? (
              <p className="optout-form-success">
                Richiesta inviata con successo! Sarai ricontattato a breve.
              </p>
            ) : (
              <form onSubmit={handleSubmit}>
                <div className="columns is-gapless">
                  <div className="column">
                    <div className="inputBox">
                      <span className="azure">Email</span>
                      <br />
                      <input
                        name="email"
                        className="input"
                        type="email"
                        placeholder="Email"
                        required
                      />

                      <ValidationError
                        prefix="Email"
                        field="email"
                        errors={state.errors}
                      />
                    </div>
                  </div>
                </div>
                <div className="columns is-gapless">
                  <div className="column">
                    <div className="inputBox">
                      <span className="azure">Indirizzo MAC</span>
                      <br />
                      <input
                        name="name"
                        className="input"
                        type="text"
                        placeholder="AA:AA:AA:AA:AA:AA"
                        id="MAC"
                        pattern="^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}\\.[0-9a-fA-F]{4}\\.[0-9a-fA-F]{4})$"
                        title="Indirizzo MAC non valido"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    Risponderemo alla richiesta entro 4 settimane.
                    <br />
                    Potremmo richiederti l’invio di un documento di identità
                    valido.
                  </div>
                </div>
                <div className="columns">
                  <div className="column sendRequest">
                    <button
                      className="button"
                      type="submit"
                      disabled={state.submitting}
                    >
                      Invia richiesta
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
       
      </div>
      <div className="section" />
    </>
  );
};

export default OptOut;
